<template>
  <Dialog class="item-dialog" :header="headerText" v-model:visible="dialogVisible" @hide="closeDialog"
    :breakpoints="{ '992px': '75vw', '768px': '100vw' }" :style="{ width: '50vw' }" dismissableMask modal maximizable>
    <div>
      <Panel header="Lisa triipkoodi järgi" class="mb-5" toggleable :collapsed="true">
        <div class="field">
          <label for="barcode">Triipkood</label>
          <div class="flex">
            <InputText id="barcode" type="text" v-model="editedItem.metadata.barcode" class="w-full" />
            <Button @click="searchBarcode" icon="pi pi-search" aria-label="Submit" class="ml-2" />
          </div>
          <small v-if="eanItemNotFound" class="text-red-500">Sellise triipkoodiga toodet ei leitud</small>
        </div>
        <div class="flex justify-content-center">
          <div class="barcode-image-container">
            <img class="barcode-image" v-if="eanItemData"
              :src="`${attachmentsBaseUrl}/${eanItemData.thumbnailRelativePath}`" :alt="eanItemData.name" />
          </div>
        </div>
      </Panel>
      <div class="field">
        <label for="name">Nimetus*</label>
        <InputText id="name" type="text" v-model="editedItem.name" :class="{ 'invalid': v$.name.$error }"
          class="w-full" />
        <small v-if="v$.name.$error" class="error">{{ v$.name.$errors[0].$message }}</small>
      </div>
      <div class="field">
        <label for="description">Kirjeldus</label>
        <Editor id="description" v-model="editedItem.description" editorStyle="height: 200px" />
      </div>
      <div class="field">
        <label for="category">Kategooria*</label>
        <Dropdown id="category" :options="categories" v-model="editedItem.category" optionLabel="name"
          :class="{ 'invalid': v$.category.$error }" class="w-full" />
        <small v-if="v$.category.$error" class="error">{{ v$.category.$errors[0].$message }}</small>
      </div>
      <div class="field">
        <label for="expiryDate">Aegumiskuupäev</label>
        <Calendar v-model="expiryDate" id="expiryDate" class="w-full" show-icon/>
      </div>
      <div class="field">
        <label for="amount">Kogus</label>
        <InputNumber v-model="editedItem.metadata.amount" id="amount" class="w-full"/>
      </div>
      <div class="field">
        <label for="location">Asukoht</label>
        <InputText id="location" type="text" v-model="editedItem.metadata.location" class="w-full" />
      </div>
      <div class="field">
        <label for="boxNumber">Kasti number</label>
        <InputText id="boxNumber" type="text" aria-describedby="boxNumber-help" v-model="editedItem.metadata.boxNumber"
          class="w-full" />
        <small id="boxNumber-help">Järjekorras järgmine vaba kastinumber on {{ nextFreeBoxNumber }}. Kasutage seda kui
          muud vajadust pole.</small>
      </div>
      <div class="field">
        <label for="attachments">Failid / pildid</label>
        <div class="mb-3" v-for="attachment in editedItemAttachments" :key="attachment.id">
          <div class="flex justify-between">
            <img v-if="utils.isImageFile(attachment.fileName)" class="image-thumbnail"
              :src="`${attachmentsBaseUrl}/${attachment.relativeFilePath}`" alt="image">
            <a v-else :href="`${attachmentsBaseUrl}/${attachment.relativeFilePath}`">{{ attachment.fileName }}</a>
            <Button severity="danger" text icon="pi pi-trash" @click="deleteAttachment(attachment.id)" />
          </div>
          <div v-if="utils.isImageFile(attachment.fileName)" class="image-filename">{{ attachment.fileName }}</div>
        </div>
        <FileUpload ref="fileUpload" name="files" :url="editedItemAttachmentsUrl" id="attachments" :multiple="true"
          @beforeSend="beforeAttachmentSend" @upload="uploadComplete" :showUploadButton="false" :showCancelButton="false">
          <template #empty>
            <p>Lohistage üleslaaditavad failid siia.</p>
          </template>
        </FileUpload>
      </div>
    </div>
    <div class="flex justify-content-end">
      <Button class="mr-2" severity="secondary" outlined label="Sulge" @click="closeDialog" />
      <Button label="Salvesta" v-if="modeIsCreate" @click="createItem" :disabled="v$.$invalid" />
      <Button label="Salvesta" v-if="modeIsEdit" @click="updateItem" :disabled="v$.$invalid" />
    </div>
  </Dialog>
</template>

<script setup>
import { toRefs, ref, reactive, watch, onMounted, computed, nextTick } from 'vue';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';
import Panel from 'primevue/panel';
import Calendar from 'primevue/calendar';
import * as ApiService from '../api-service';
import * as utils from '@/utils.js';
import { required } from '@/validators';
import { useVuelidate } from '@vuelidate/core';
import { useToast } from 'primevue/usetoast';
import Editor from 'primevue/editor';
import { formatDate } from '@/filters';
import InputNumber from 'primevue/inputnumber';

const props = defineProps({
  visible: {
    type: Boolean,
    required: true
  },
  item: {
    type: Object,
    default: undefined
  },
  eanItem: {
    type: Object,
    default: null
  }
});

const emit = defineEmits(['hide', 'create:item', 'update:item']);

const { visible } = toRefs(props);
const dialogVisible = ref(props.visible);
const eanItemData = ref(props.eanItem);
const categories = ref([]);
const editedItem = reactive({
  metadata: {}
});
const fileUpload = ref(null);
const editedItemAttachmentsUrl = ref(null);
const editedItemAttachments = ref([]);
const nextFreeBoxNumber = ref(null);
const attachmentsBaseUrl = process.env.VUE_APP_ATTACHMENTS_BASEURL
const eanItemNotFound = ref(false);
const expiryDate = ref(null);

const toast = useToast();
const rules = computed(() => {
  return {
    name: { required, $autoDirty: true },
    category: { required, $autoDirty: true }
  };
});

const v$ = useVuelidate(rules, editedItem);

onMounted(async () => {
  const response = await ApiService.getAllInventoryCategories();
  categories.value = response.data;
  await loadNextFreeBoxNumber();
});

const modeIsEdit = computed(() => {
  return !!props.item;
});

const modeIsCreate = computed(() => {
  return !props.item;
});

const headerText = computed(() => {
  if (modeIsEdit.value) return 'Asja muutmine';

  return 'Uue asja lisamine';
});

watch(visible, async (newValue) => {
  dialogVisible.value = newValue;

  if (props.item) {
    Object.assign(editedItem, props.item);
    editedItemAttachments.value = (await ApiService.getInventoryItemAttachments(props.item.id)).data;
    if (editedItem.metadata.expiryDate) {
      expiryDate.value = new Date(editedItem.metadata.expiryDate);
    }
  }

  if (props.eanItem) {
    eanItemData.value = props.eanItem;
  } else {
    eanItemData.value = null;
  }
});

watch(eanItemData, (newValue) => {
  if (newValue === null) {
    return;
  }

  eanItemNotFound.value = false;
  editedItem.name = eanItemData.value.name;
  editedItem.metadata.barcode = eanItemData.value.code;
  editedItem.metadata.eanItemId = eanItemData.value.id;
});

watch(expiryDate, () => {
  editedItem.metadata.expiryDate = formatDate(expiryDate.value, 'YYYY-MM-DD');
});

const closeDialog = () => {
  Object.assign(editedItem, {
    name: undefined,
    description: undefined,
    category: undefined,
    metadata: {}
  });
  expiryDate.value = null;
  v$.value.$reset();
  emit('hide');
};

const createItem = async () => {
  const response = await ApiService.createInventoryItem(editedItem);
  editedItemAttachmentsUrl.value = `${process.env.VUE_APP_API_URL}/inventory/items/${response.data.id}/attachments`;
  await nextTick();
  if (fileUpload.value.files.length > 0) {
    fileUpload.value.upload();
  } else {
    toast.add({
      severity: 'success',
      summary: 'Õnnestus',
      detail: 'Uus asi lisatud',
      life: 5000
    }
    );
    closeDialog();
  }
  emit('create:item', response.data);
};

const updateItem = async () => {
  const response = await ApiService.updateInventoryItem(editedItem);
  editedItemAttachmentsUrl.value = `${process.env.VUE_APP_API_URL}/inventory/items/${response.data.id}/attachments`;
  await nextTick();
  if (fileUpload.value.files.length > 0) {
    fileUpload.value.upload();
  } else {
    toast.add({
      severity: 'success',
      summary: 'Õnnestus',
      detail: 'Muudatused salvestatud',
      life: 5000
    }
    );
    closeDialog();
    emit('update:item');
  }
};

const beforeAttachmentSend = (event) => {
  event.xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('access_token')}`);
};

const uploadComplete = (event) => {
  const responses = JSON.parse(event.xhr.response);
  const failedUploads = [];
  responses.forEach(response => {
    if (response.status === 'FAILURE') {
      failedUploads.push(response);
    }
  });

  const toastDetailMessage = modeIsEdit.value ? 'Muudatused salvestatud' : 'Uus asi lisatud';
  toast.add({
    severity: 'success',
    summary: 'Õnnestus',
    detail: toastDetailMessage,
    life: 5000
  }
  );

  closeDialog();
  emit('update:item');

  if (failedUploads.length > 0) {
    toast.add({
      severity: 'error',
      summary: 'Viga',
      detail: 'Osade failide üleslaadimine ebaõnnestus',
      life: 5000
    }
    );
  }
};

const deleteAttachment = async (attachmentId) => {
  await ApiService.deleteAttachmentFile(attachmentId);
  editedItemAttachments.value = editedItemAttachments.value
    .filter(attachment => attachment.id !== attachmentId);
};

const loadNextFreeBoxNumber = async () => {
  const response = await ApiService.getNextFreeBoxNumber();
  nextFreeBoxNumber.value = response.data;
}

const searchBarcode = async () => {
  if (!editedItem.metadata.barcode) {
    return;
  }

  try {
    const result = await ApiService.searchEanItem(editedItem.metadata.barcode);
    if (result.data.name) {
      eanItemData.value = result.data;
    }
  } catch (error) {
    console.log(error);
    eanItemNotFound.value = true;
  }
}
</script>

<style scoped>
.image-thumbnail {
  width: 120px;
}

.image-filename {
  font-size: 0.7em;
}

.barcode-image-container {
  max-width: 200px;
  max-height: 200px;
}

.barcode-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 768px) {
  .item-dialog {
    max-height: 100% !important;
  }
}
</style>
