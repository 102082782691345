<template>
  <Dialog v-model:visible="dialogVisible" @hide="closeDialog" :style="{ width: '50vw' }" :breakpoints="{'768px': '95vw'}" :header="dialogHeader">
    <div id="barcode-reader" v-show="!eanItem"></div>
    <div v-if="scannedBarcode">Triipkood: {{ scannedBarcode }}</div>
    <div class="flex justify-content-center">
      <div v-if="eanItem" class="barcode-image-container">
        <img class="barcode-image" :src="`${attachmentsBaseUrl}/${eanItem.thumbnailRelativePath}`" :alt="eanItem.name"/>
      </div>
    </div>
    <div v-if="eanItemLoading" class="text-center mt-2">
      <ProgressSpinner style="width: 50px; height: 50px"/>
    </div>
    <div v-if="eanItemNotFound" class="text-red-500">Sellise triipkoodiga toodet ei leitud.</div>
    <template #footer>
        <Button v-if="eanItem || eanItemNotFound" outlined label="Skanneeri uuesti" @click="scanAgain"/>
        <Button v-if="eanItem" label="Lisa" @click="addItem"/>
        <Button v-if="!eanItem" severity="secondary" outlined label="Katkesta" @click="closeDialog"/>
    </template>
  </Dialog>  
</template>

<script setup>
  import { ref, watch, toRefs, nextTick, onMounted, computed } from 'vue';
  import Dialog from 'primevue/dialog';
  import Button from 'primevue/button';
  import ProgressSpinner from 'primevue/progressspinner';
  import { Html5Qrcode } from 'html5-qrcode';
  import * as ApiService from '../api-service';

  const props = defineProps({
    visible: {
      type: Boolean,
      default: false
    }
  });

  const emit = defineEmits(['close', 'scan']);

  const { visible } = toRefs(props);

  const scannerConfig = { fps: 10, qrbox: { width: 250, height: 250 } };
  const attachmentsBaseUrl = process.env.VUE_APP_ATTACHMENTS_BASEURL;
  const dialogVisible = ref(props.visible);
  const eanItem = ref(null);
  const scannedBarcode = ref(null);
  const eanItemNotFound = ref(false);
  const eanItemLoading = ref(false);

  let html5QrCode;

  watch(visible, async (newValue) => {
    dialogVisible.value = newValue;

    if (newValue === true) {
      initializeScanner();
    }
  });

  const dialogHeader = computed(() => {
    if (!eanItem.value) {
      return 'Skanneeri triipkood';
    }

    return eanItem.value.name;
  });

  const qrCodeSuccessCallback = async (decodedText) => {
      await html5QrCode.stop();
      scannedBarcode.value = decodedText;
      try {
        eanItemLoading.value = true;
        const response = await ApiService.searchEanItem(decodedText);
        eanItem.value = response.data;
      } catch (error) {
        eanItemNotFound.value = true;
      } finally {
        eanItemLoading.value = false;
      }
    };

  const initializeScanner = async () => {
    await nextTick();

    html5QrCode = new Html5Qrcode('barcode-reader');
    html5QrCode.start({ facingMode: 'environment' }, scannerConfig, qrCodeSuccessCallback);
  }

  const scanAgain = () => {
    eanItem.value = null;
    eanItemNotFound.value = false;
    scannedBarcode.value = null;
    html5QrCode.start({ facingMode: 'environment' }, scannerConfig, qrCodeSuccessCallback);
  }

  const addItem = () => {
    emit('scan', eanItem.value);
    closeDialog();
  }

  const closeDialog = async () => {
    eanItem.value = null;
    emit('close');
  }
</script>
<style scoped>
.barcode-image-container {
  width: 200px;
  height: 200px;
}

.barcode-image {
    width: 100%;
    height: 100%; 
    object-fit: contain;
}
</style>