<template>
  <BarcodeScanner :visible="scanDialogVisible" @close="scanDialogVisible = false" @scan="handleScannedEanItem"/>

  <ItemsFilters class="mb-2"/>

  <div class="grid my-3">
    <div class="col-12 md:col text-right">
      <Button label="Skanneeri" icon="pi pi-camera" outlined @click="scanBarcode" class="mr-2"/>
      <Button label="Lisa uus asi" icon="pi pi-plus" @click="openItemDialog"/>
    </div>
  </div>

  <DataView :value="items.content" :layout="layout" :paginator="true" :lazy="true" :rows="items.size"
            :totalRecords="items.totalSize" @page="handlePageChange" :first="firstItem">
    <template #list="slotProps">
      <div class="item-wrapper flex mb-3 p-2 shadow-1">
        <div class="item-contents flex">
          <div class="item-image flex mr-3 align-items-center item-thumbnail-wrapper">
            <img v-if="slotProps.data.thumbnailRelativePath" class="item-thumbnail"
                 :src="`${attachmentsBaseUrl}/${slotProps.data.thumbnailRelativePath}`" alt="thumbnail"
                 @click="openItemDetail(slotProps.data.id)">
          </div>
          <div class="item-text-wrapper">
            <div class="item-text flex flex-wrap">
              <div class="item-text__main">
                <div class="flex justify-content-between align-items-center">
                  <div class="font-bold inline-flex">
                    <router-link :to="{ name: 'Item', params: { id: slotProps.data.id} }">{{
                        slotProps.data.name
                      }}
                    </router-link>
                  </div>
                  <div class="inline-flex">
                    <Button icon="pi pi-pencil" text @click="editItem(slotProps.data)"/>
                    <Button severity="danger" text icon="pi pi-trash"
                            @click="deleteItem(slotProps.data.id)"/>
                  </div>
                </div>
                <div class="item-text__description">
                  {{ formatItemDescriptionPreview(slotProps.data.description) }}
                </div>
              </div>
              <div class="grid item-text__metadata">
                <div class="col-12 md:col-3 py-0">
                  <span class="item-text__metadata__icon pi pi-book mr-1"></span>Kategooria:
                  {{ slotProps.data?.category?.name }}
                </div>
                <div class="col-12 md:col-3 py-0">
                  <span v-if="slotProps.data.metadata?.boxNumber">
                    <span class="item-text__metadata__icon pi pi-folder mr-1"></span>Kasti number:
                  {{ slotProps.data.metadata?.boxNumber }}
                  </span>
                </div>
                <div class="col-12 md:col-3 py-0">
                  <span v-if="slotProps.data.metadata?.location">
                    <span class="item-text__metadata__icon pi pi-compass mr-1"></span>Asukoht:
                  {{ slotProps.data.metadata?.location }}
                  </span>
                </div>
                <div class="col-12 md:col-3 py-0">
                  <span v-if="slotProps.data.metadata?.expiryDate">
                    <span class="item-text__metadata__icon pi pi-calendar mr-1"></span>Aegumiskuupäev:
                  {{ formattedDate(slotProps.data.metadata?.expiryDate) }}
                  </span>
                </div>
                <div class="col-12 md:col-3 py-0">
                  <span v-if="slotProps.data.metadata?.amount">
                    <span class="item-text__metadata__icon pi pi-database mr-1"></span>Kogus:
                  {{ slotProps.data.metadata?.amount }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </DataView>

  <ItemDialog
      :visible="itemDialogVisible"
      :item="editedItem"
      :ean-item="eanItem"
      @hide="hideItemDialog"
      @create:item="addItem"
      @update:item="updateItem"
      ref="itemDialog"
  />
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import * as ApiService from '../api-service';
import DataView from 'primevue/dataview';
import Button from 'primevue/button';
import ItemDialog from '@/components/ItemDialog';
import ItemsFilters from '@/components/ItemsFilters.vue';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';
import { useRoute, useRouter } from 'vue-router';
import Dropdown from 'primevue/dropdown';
import ItemSearchBar from '@/components/ItemSearchBar';
import BarcodeScanner from '@/components/BarcodeScanner';
import { PAGE_SIZE } from '@/constants';
import { formattedDate } from '@/filters';

const items = ref([]);
const layout = ref('list');
const editedItem = ref(undefined);
const sortOrder = ref('createdAt,desc');
const attachmentsBaseUrl = process.env.VUE_APP_ATTACHMENTS_BASEURL;
const sortOptions = [
  { name: 'Nimetuse järgi kasvavalt', code: 'name,asc' },
  { name: 'Nimetuse järgi kahanevalt', code: 'name,desc' },
  { name: 'Lisamise kuupäeva järgi kasvavalt', code: 'createdAt,asc' },
  { name: 'Lisamise kuupäeva järgi kahanevalt', code: 'createdAt,desc' }
];
const firstItem = ref(0);
const scanDialogVisible = ref(false);
let itemDialogVisible = ref(false);
const itemDialog = ref();
const eanItem = ref(null)

const router = useRouter();
const route = useRoute();
const toast = useToast();
const confirm = useConfirm();

onMounted(async () => {
  await loadItems();
});

watch(sortOrder, async () => {
  await loadItems();
});

watch(() => route.query, async () => {
  await loadItems();
});

const loadItems = async (additionalParams) => {
  const params = {
    sort: sortOrder.value,
    ...additionalParams
  };

  if (route.query.page) {
    firstItem.value = (route.query.page - 1) * PAGE_SIZE;
    params['page'] = route.query.page - 1;
  }

  if (route.query.query) {
    params['query'] = route.query.query;
  }

  if (route.query.categoryId) {
    params['categoryId'] = route.query.categoryId;
  }

  if (route.query.sort) {
    params['sort'] = route.query.sort;
  }

  const response = await ApiService.getAllInventoryItems(params);
  items.value = response.data;
};

const openItemDialog = async () => {
  await router.push({ name: 'ItemCreate' });
};

const addItem = async () => {
  await loadItems();
};

const editItem = (item) => {
  editedItem.value = item;
  router.push({ name: 'ItemEdit', params: { id: item.id } });
};

const updateItem = async () => {
  await loadItems();
};

const deleteItem = async (itemId) => {
  confirm.require({
    message: 'Kas olete kindel, et soovite valitud asja kustutada?',
    header: 'Asja kustutamine',
    icon: 'pi pi-exclamation-triangle',
    accept: async () => {
      await ApiService.deleteInventoryItem(itemId);
      items.value.content = items.value.content.filter(item => item.id !== itemId);
      toast.add({
            severity: 'success',
            summary: 'Õnnestus',
            detail: 'Asi kustutatud',
            life: 5000
          }
      );
    }
  });
};

const handlePageChange = async (event) => {
  const queryParams = Object.assign({}, route.query);
  queryParams.page = event.page + 1;
  await router.push({ name: 'Items', query: queryParams });
};

const openItemDetail = (itemId) => {
  router.push({ name: 'Item', params: { id: itemId }});
};

const hideItemDialog = () => {
  itemDialogVisible.value = false;
  editedItem.value = undefined;
  eanItem.value = null;
};

const formatItemDescriptionPreview = (description) => {
  if (!description) {
    return description;
  }

  const maxLength = 100;
  const trimmedDescription = description.replace(/<(.|\n)*?>/g, '');

  if (trimmedDescription.length > maxLength) {
    return trimmedDescription.substring(0, maxLength) + '...';
  }

  return trimmedDescription;
};

const handleScannedEanItem = async (item) => {
  eanItem.value = item;
  await router.push({ name: 'ItemCreate', query: { eanItemId: item.id } });
}

const scanBarcode = async () => {
  scanDialogVisible.value = true;
}
</script>

<style scoped>
.item-wrapper {
  width: 100%;
  border-radius: 3px;
  height: 150px;
}

.item-text-wrapper {
  width: 100%;
}

.item-thumbnail-wrapper {
  width: 120px;
  cursor: pointer;
}

.item-thumbnail {
  max-width: 100%;
  max-height: 100%;
}

.item-text {
  height: 100%;
}

.item-text__main {
  width: 100%;
}

.item-text__metadata {
  width: 100%;
  align-self: flex-end;
  font-size: 0.7rem;
  overflow: hidden;
}

.item-text__metadata__icon {
  font-size: 0.7rem;
}

.item-contents {
  width: 100%;
}

.item-text__description {
  word-break: break-all;
}

@media screen and (max-width: 768px) {
  .item-text__description {
    display: none;
  }

  .item-text__metadata__location {
    display: none;
  }
}
</style>
