import axios from 'axios';
import qs from 'qs';

// ---------
// # GENERAL
// ---------

const API_URL = process.env.VUE_APP_API_URL;

function login(loginData) {
  return axios.post(`${API_URL}/login`, loginData);
}

function signup(signupData) {
  return axios.post(`${API_URL}/users/register`, {
    username: signupData.username,
    rawPassword: signupData.password
  });
}

function getCurrentUser() {
  return axios.get(`${API_URL}/users/current`);
}

function refreshTokens() {
  const refreshData = {
    'grant_type': 'refresh_token',
    'refresh_token': localStorage.getItem('refresh_token')
  };

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }

  return axios.post(`${API_URL}/oauth/access_token`, qs.stringify(refreshData), config)
}

// -----------------
// # Inventory items
// -----------------

function getAllInventoryItems(params) {
  return axios.get(`${API_URL}/inventory/items`, {
      params
    }
  );
}

function quickSearch(query) {
  return axios.get(`${API_URL}/inventory/items/quick-search`, {
      params: {
        query
      }
    }
  );
}

function getInventoryItem(itemId) {
  return axios.get(`${API_URL}/inventory/items/${itemId}`);
}

function getInventoryItemAttachments(itemId) {
  return axios.get(`${API_URL}/inventory/items/${itemId}/attachments`);
}

function getNextFreeBoxNumber() {
  return axios.get(`${API_URL}/inventory/items/next-box-number`);
}

function createInventoryItem(item) {
  return axios.post(`${API_URL}/inventory/items`, item);
}

function updateInventoryItem(item) {
  return axios.put(`${API_URL}/inventory/items/${item.id}`, item);
}

function deleteInventoryItem(itemId) {
  return axios.delete(`${API_URL}/inventory/items/${itemId}`);
}

// ----------------------
// # Inventory categories
// ----------------------

function getAllInventoryCategories() {
  return axios.get(`${API_URL}/inventory/categories`);
}

function createInventoryCategory(category) {
  return axios.post(`${API_URL}/inventory/categories`, category);
}

function updateInventoryCategory(category) {
  return axios.put(`${API_URL}/inventory/categories/${category.id}`, category);
}

function deleteInventoryCategory(categoryId) {
  return axios.delete(`${API_URL}/inventory/categories/${categoryId}`);
}

// ------------------
// # Attachment files
// ------------------

function deleteAttachmentFile(id) {
  return axios.delete(`${API_URL}/inventory/items/attachments/${id}`);
}

// ------------
// # EAN search
// ------------

function searchEanItem(eanCode) {
  return axios.get(`${API_URL}/ean/search/${eanCode}`);
}

function getEanItem(id) {
  return axios.get(`${API_URL}/ean/${id}`);
}

// ------------
// # Admin
// ------------

function generateThumbnails() {
  return axios.post(`${API_URL}/admin/generate-thumbnails`)
}

export {
  login,
  signup,
  getCurrentUser,
  refreshTokens,
  getAllInventoryItems,
  quickSearch,
  getInventoryItem,
  getInventoryItemAttachments,
  getNextFreeBoxNumber,
  createInventoryItem,
  updateInventoryItem,
  deleteInventoryItem,
  getAllInventoryCategories,
  createInventoryCategory,
  updateInventoryCategory,
  deleteInventoryCategory,
  deleteAttachmentFile,
  searchEanItem,
  getEanItem,
  generateThumbnails
};
